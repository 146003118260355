/*
Affiche le bandeau du User
prop data = {user: IDxxx?, owner: IDxxx, customer: IDxxx}

*/

<template>
   <div class="container justify-content-center gx-0">

    <b-modal ref="imgModal" id="imgModal" size="lg" @ok="doChangeImage">
      <div class="container">
          <div class="row">
              <div class="col" v-if="field=='bandeau'">
                Photo bandeau<br/> <!-- img.bandeau -->
                <ImageUpdater @imageChange="imageChangeBandeau"/>
              </div>
              <div class="col" v-if="field=='image'">
                <img :src="img.avatar" style="max-width:200px;"/><br/>
                Photo Avatar (visible en mode public)<br/>
                <ImageUpdater @imageChange="imageChangeAvatar"/>
              </div>
              <div class="col" v-if="field=='image'">
                <img :src="img.image" style="max-width:200px;"/><br/>
                Photo Identité (visible en mode privé & secours)<br/>
                <ImageUpdater @imageChange="imageChangeImage"/>
              </div>
          </div>
      </div>
    </b-modal>

      <div id="bandeau" class="row">
         <div class="col-md-12 col-12 user-detail-main border mb-3 pb-2">
            <div class="row align-items-center">
               <div id="thebandeau" class="col-md-12 col-12 profile-back pb-5 d-flex align-items-center justify-content-center" data-type='bandeau' @click="doUpdImage" :style="'background-image: url(\''+img.bandeau+'\');'">
                 <img class='masque' src="/Photo_Masque.png" style="" data-type='image' @click="doUpdImage" alt="Profil">
                 <img v-if="view=='public'" :src="img.avatar" style="" alt="Profil0" @click="doUpdImage" class="image rounded-circle img-fluid">
                 <img v-if="view!='public'" :src="img.image" style="" alt="Profil0" @click="doUpdImage" class="image rounded-circle img-fluid">
               </div>
            </div>
            <div class="row">
               <div class="col-md-12 col-12 user-detail text-center">
                  <h4 v-if="owner" class="" style="margin-top: 32vh;"><strong>{{owner.pseudo}}</strong></h4>
                  <div class="form-group"></div>
               </div>
            </div>
         </div>
      </div>

   </div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";
import ImageUpdater from '@/components/ImageUpdater.vue'

export default {
  data(){
      return {
        img: {bandeau: '/default_bandeau.jpg', image: '/Mascotte_01B.png', avatar: '/Mascotte_01B.png'},
        user: null,
        customer: null, owner: null,
        field:'', // image ou bandeau
        tempImage:null, // stokage image image
        tempBandeau:null, // stokage image bandeau
        tempAvatar:null, // stokage image avatar
      }
  },
  props:['data','view', 'enableEdit'], // TODO => {owner: XXX, customer: XXX}
  name: "Bandeau",
  components: {
    ImageUpdater,
  },
  methods: {
    doUpdImage(evt){
      if (!this.enableEdit | this.data.customer) return;
      console.log('doUpdImage', evt.target.dataset.type);
      evt.stopPropagation();
      this.field = evt.target.dataset.type;
      this.$refs.imgModal.show();
    },
    doChangeImage(){
      console.log('doChangeImage', this.field)
      let data={id:this.data.owner}
      if (this.field=='bandeau') {
        if (this.tempBandeau) data.bandeau=this.tempBandeau;
        }
      if (this.field=='image') {
        if (this.tempAvatar) data.avatar=this.tempAvatar;
        if (this.tempImage) data.image=this.tempImage;
        }
      MyFlashDS.saveUser(data).then(()=>{
          this.doLoadImages()
      }).catch((error)=>{
          console.log(error)
          alert("Votre image n'a pas été mise à jour : elle est trop grosse")
      })
    },
    imageChangeImage(data){this.tempImage=data},
    imageChangeAvatar(data){this.tempAvatar=data},
    imageChangeBandeau(data){this.tempBandeau=data},
    doSetData(data) {
        this.img.bandeau = (data.bandeau ? process.env.VUE_APP_ROOT_API+'/..'+data.bandeau : '/default_bandeau.jpg')
        this.img.image = (data.image ? process.env.VUE_APP_ROOT_API+'/..'+data.image : '/Mascotte_01B.png')
        this.img.avatar = (data.avatar ? process.env.VUE_APP_ROOT_API+'/..'+data.avatar : '/Mascotte_01B.png')
    },
    doLoadImages(){
        console.log('Bandeau,data:', this.data)
        if (false & this.data.customer)
            MyFlashDS.getOneCustomer(this.data.customer, 'name bandeau image').then(res=>{
              this.customer=res.data;
              //this.img = res.data;
              this.doSetData(res.data)
                //this.img.bandeau = (this.img.bandeau ? process.env.VUE_APP_ROOT_API+'/..'+this.img.bandeau : '/default_bandeau.jpg')
                //this.img.image = (this.img.image ? process.env.VUE_APP_ROOT_API+'/..'+this.img.image : '/Mascotte_01B.png')
            });
        if (this.data.owner)
            MyFlashDS.dataGetUser(this.data.owner, 'id bandeau image avatar pseudo').then(res=>{
              this.owner=res.data;
              if (true | !this.data.customer) this.doSetData(res.data);
              //this.user = res.data;
              //this.img = res.data;
              //this.bandeau = (this.data && this.data.customer && this.data.customer.image) ? this.data.customer.image : ((this.user && this.user.image) ? this.user.image : '/default_bandeau.jpg');
                //this.img.bandeau = (this.img.bandeau ? process.env.VUE_APP_ROOT_API+'/..'+this.img.bandeau : '/default_bandeau.jpg')
                //this.img.image = (this.img.image ? process.env.VUE_APP_ROOT_API+'/..'+this.img.image : '/Mascotte_01B.png')
            });
    },
  },
  mounted() {
    this.doLoadImages()
  }
}
</script>

<style scoped>

#thebandeau {
  position : absolute;
  width: 100vw;
  margin-left: -50vw;
  left: 50%;
  top: 0px;
}
img.masque {
  height: 22vh;
  width: 22vh;
  position: absolute;
  z-index: 2;
  border-radius: 100%;
  cursor: pointer;
}
img.image {
  position: absolute; z-index: 1; background-color: rgb(166, 166, 166);
}

.user-detail {
  padding-top: 13vh;
}

.profile-back {
  height: 35vh;
  background-size: 100% 100%;
  background-size: cover;
  background-position: center;
}
.profile-back img {
  height: 20vh;
  width: 20vh;
  margin-top: 20vh;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  display: block;
  object-fit: cover;
}
@-moz-document url-prefix(){
  .profile-back img{
    margin-top: 42vh;
  }
  .textOnPhoto{
    margin-top: 42v!important;
  }
}
</style>