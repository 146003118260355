import { render, staticRenderFns } from "./FlashView.vue?vue&type=template&id=b4cf52be&scoped=true&"
import script from "./FlashView.vue?vue&type=script&lang=js&"
export * from "./FlashView.vue?vue&type=script&lang=js&"
import style0 from "./FlashView.vue?vue&type=style&index=0&id=b4cf52be&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4cf52be",
  null
  
)

export default component.exports